import * as React from "react";
import { getAppMode } from "../../config/getAppMode";

const App = React.lazy(() => import("./App"));
const PreviewApp = React.lazy(() => import("./PreviewApp"));

export function Application(): React.ReactElement {
    const Component = getAppMode() === "preview" ? PreviewApp : App;
    return (
        <React.Suspense fallback={<div />}>
            <Component />
        </React.Suspense>
    );
}
