import * as ReactDOM from "react-dom";
import { Application } from "./components/app/Application";
import { unregister } from "./registerServiceWorker";
import "./static/css/index.scss";

ReactDOM.render(
    <Application />,
    document.getElementById("root") as HTMLElement,
);

unregister();
